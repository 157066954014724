// import { useLocation } from "react-router"
// import { Link } from "react-router-dom"



export const Services = (props) => {


  // const location = useLocation();
  

  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Services</h2>
          <p>
              Innovative Planning For Successful Events
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  {' '}
                  <i className={d.icon}></i>
                  {/* {location.pathname === '/'?
                  <a href='/'>
                  <Link to = "/" onClick={() => {if (window.confirm('Please Log In to Proceed')) <Link/>}}>
                    <i className={d.icon}></i>
                    </Link>
                </a>
          :
          <a href='/'>
          <Link to = "/Request">
            <i className={d.icon}></i>
            </Link>
        </a>
          } */}
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
        {/* <div className='row'>
          {location.pathname === '/'?
          <h6>
            log in to make a request
          </h6>
          :
          <z1>
          <Link to='/request' className = "service-link">
            Click here to make a request
          </Link>
          </z1>

          }
        </div> */}
      </div>
    </div>
  )
}
