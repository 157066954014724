// import { Link} from "react-router-dom";
// import { useLocation } from "react-router";
export const Navigation = (props) => {

  // const location = useLocation();
  
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>

        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
                <img src="img/a.ico" alt="" style={{float:'left',width:'50px',height:'50px'}}/>
          <a className='navbar-brand page-scroll' href='#page-top' >
            <p >
            Concept Events Toronto Inc.</p>
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            {/* <li>
              <a href='#features' className='page-scroll'>
                Features
              </a>
            </li> */}
            <li>
              <a href='#about' className='page-scroll'>
                About
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
                Services
              </a>
            </li>
            <li>
              <a href='#portfolio' className='page-scroll'>
                Gallery
              </a>
            </li>
            <li>
              <a href='#testimonials' className='page-scroll'>
                Testimonials
              </a>
            </li>
            {/* <li>
              <a href='#team' className='page-scroll'>
                Team
              </a>
            </li> */}
            <li>
              <a href='#contact' className='page-scroll'>
                Contact
              </a>
            </li>

            {/* {location.pathname === '/' ? 
            <>
            <li>
              <a href='/'>
                <Link to = "/login">
                  LogIn
                </Link>
              </a>
            </li>
            <li>
              <a href='/'>
                <Link to = "/signup">
                  SignUp
                </Link>
              </a>
            </li> 
            </>
            :
            <li>
              <a href='/'> 
                <Link to = '/' onClick={() => {if (window.confirm('Press ok to Sign Out')) <Link/>}}>
                  Sign Out
                </Link>  
                
              </a>
            </li>
            } */}

          </ul>
        </div>
      </div>
    </nav>
  )
}
